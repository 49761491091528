import Axios from 'axios'
import {
  CART_ADD_ITEM,
  CART_ADD_FLAVOR,
  CART_REMOVE_ITEM,
  CART_SAVE_PAYMENT_METHOD,
} from '../constants/cartConstants'

export const addToCart =
  (productId, qty, discountPercent) => async (dispatch, getState) => {
    const { data } = await Axios.get(`/api/products/${productId}`)

    //R apply discount policy on-fly here
    if (discountPercent <= 0) {
      data.discountPercent = 0
    }
    dispatch({
      type: CART_ADD_ITEM,
      payload: {
        name: data.name,
        category: data.category,
        image: data.image,
        price: data.price,
        discountPercent: data.discountPercent,
        priceAfterDiscount: (data.price * (100 - data.discountPercent)) / 100,
        countInStock: data.countInStock,
        product: data._id,
        qty,
      },
    })
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
  }

export const addFlavorToCart =
  (productId, qty, flavor) => async (dispatch, getState) => {
    const { data } = await Axios.get(`/api/products/${productId}`)

    dispatch({
      type: CART_ADD_FLAVOR,
      payload: {
        name: data.name + ' (' + flavor + ')',
        category: data.category,
        image: data.image,
        price: data.price,
        discountPercent: data.discountPercent,
        priceAfterDiscount: (data.price * (100 - data.discountPercent)) / 100,
        countInStock: data.countInStock,
        product: data._id,
        qty,
      },
    })
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
  }

export const removeFromCart = (productId) => (dispatch, getState) => {
  dispatch({ type: CART_REMOVE_ITEM, payload: productId })
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({ type: CART_SAVE_PAYMENT_METHOD, payload: data })
}
