import {
  EMAIL_SEND_REQUEST,
  EMAIL_SEND_SUCCESS,
  EMAIL_SEND_FAIL,
} from '../constants/emailConstants'

import emailjs from 'emailjs-com'

//Note: {state} is not used, one-time action
export const userEmailReducer = (state = {}, action) => {
  switch (action.type) {
    case EMAIL_SEND_REQUEST:
      return { state }

    case EMAIL_SEND_SUCCESS:
      const data = action.payload
      const {
        remindMonth,
        remindDate31,
        selectedHour,
        remindMinute,
        name,
        replyto,
        phone,
        seats,
        note,
      } = data

      var templateParams = {
        remindMonth: `${remindMonth}`,
        remindDate31: `${remindDate31}`,
        selectedHour: `${selectedHour}`,
        remindMinute: `${remindMinute}`,
        name: `${name}`,
        replyto: `${replyto}`,
        phone: `${phone}`,
        seats: `${seats}`,
        note: `${note}`,
      }

      /* console.log('in reducer', data) */

      /* sending email to sasayaE@gmail.com and customer */
      /* use remind template template_u2ggxs4 */
      emailjs
        .send(
          'service_fxk654t',
          'template_u2ggxs4',
          templateParams,
          'user_maDf8IdykJuFsIAPHEQ3h'
        )
        .then(
          (result) => {
            console.log('remind email send successfully')
          },
          (error) => {
            console.log('remind email send fail')
          }
        )
      return state

    case EMAIL_SEND_FAIL:
      return state
    default:
      return state
  }
}
