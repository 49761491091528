import React from 'react'
import { FaTimes } from 'react-icons/fa'

import Product from '../Product'

import './SubmenuModal.css'

//subCats: array of sub Categories
//closeModal: func handler
function SubmenuModal({ products, mainMenu, funcHandle }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              funcHandle(false)
            }}
          >
            <FaTimes />
          </button>
        </div>

        {/* Appetizer */}
        {mainMenu === 'Appetizer' && (
          <div>
            {/* 滷味 STEWED APPETIZER */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>滷味 STEWED APPETIZER</h1>
              </div>

              <div className="category-img-container">
                <img
                  src="/images/StewedAppetizer_2.jpg"
                  alt=""
                  className="category-img"
                />
              </div>

              {products
                .filter((product) => product.category === 'STEWED APPETIZER')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>

            {/* 滷味拼盤 STEWED COMBO */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>滷味拼盤 STEWED COMBO</h1>
              </div>

              <div className="category-img-container">
                <img
                  src="/images/StewedCombo.jpg"
                  alt=""
                  className="category-img"
                />
              </div>

              {products
                .filter((product) => product.category === 'STEWED COMBO')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>

            {/* 小菜 APPETIZERS */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>小菜 APPETIZERS</h1>
              </div>

              <div className="category-img-container">
                <img
                  src="/images/Appetizers.jpg"
                  alt=""
                  className="category-img"
                />
              </div>

              {products
                .filter((product) => product.category === 'APPETIZERS')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>
          </div>
        )}

        {/* Fried */}
        {mainMenu === 'Fried' && (
          <div>
            {/* 炸點 DEEP FRIED SNACK */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>炸點 DEEP FRIED SNACK</h1>
              </div>

              <div className="category-img-container">
                <img
                  src="/images/DeepFriedSnack.jpg"
                  alt=""
                  className="category-img"
                />
              </div>

              {products
                .filter((product) => product.category === 'DEEP FRIED SNACK')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>

            {/* 素炸點 VEGETARIAN DEEP FRIED SNACK */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>素炸點 VEGETARIAN DEEP FRIED SNACK</h1>
              </div>

              <div className="category-img-container">
                <img
                  src="/images/VegetarianDeepFriedSnack.jpg"
                  alt=""
                  className="category-img"
                />
              </div>

              {products
                .filter(
                  (product) =>
                    product.category === 'VEGETARIAN DEEP FRIED SNACK'
                )
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>
          </div>
        )}

        {/* Hotpot */}
        {mainMenu === 'Hotpot' && (
          <div>
            {/* 韓式鍋品KOREAN STYLE HOT POT */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>韓式鍋品 KOREAN STYLE HOT POT</h1>
              </div>

              <div className="category-img-container">
                <img
                  src="/images/KoreanStyleHotPot.jpg"
                  alt=""
                  className="category-img"
                />
              </div>

              {products
                .filter(
                  (product) => product.category === 'KOREAN STYLE HOT POT'
                )
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>

            {/* 素食鍋品 VEGETARIAN POT */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>素食鍋品 VEGETARIAN POT</h1>
              </div>

              <div className="category-img-container">
                <img
                  src="/images/VegetarianPot.jpg"
                  alt=""
                  className="category-img"
                />
              </div>

              {products
                .filter((product) => product.category === 'VEGETARIAN POT')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>
          </div>
        )}

        {/* DishesMeals */}
        {mainMenu === 'DishesMeals' && (
          <div>
            {/* 素食餐點 VEGETARIAN MEAL*/}
            <div className="grid-col2">
              <div className="category-title">
                <h1>素食餐點 VEGETARIAN MEAL</h1>
              </div>

              <div className="category-img-container">
                <img
                  src="/images/VegetarianMeal.jpg"
                  alt=""
                  className="category-img"
                />
              </div>

              {products
                .filter((product) => product.category === 'VEGETARIAN MEAL')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>

            {/* 飯類 RICE MEAL */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>飯類 RICE MEAL</h1>
              </div>

              <div className="category-img-container">
                <img
                  src="/images/RiceMeal.jpg"
                  alt=""
                  className="category-img"
                />
              </div>

              {products
                .filter((product) => product.category === 'RICE MEAL')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>

            {/* 燴飯類 SAUCED MEAL */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>燴飯類 SAUCED MEAL</h1>
              </div>

              <div className="category-img-container">
                <img
                  src="/images/SaucedMeal.jpg"
                  alt=""
                  className="category-img"
                />
              </div>

              {products
                .filter((product) => product.category === 'SAUCED MEAL')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>

            {/* 新餐點 NEW MENU */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>新餐點 NEW MENU</h1>
              </div>

              {products
                .filter((product) => product.category === 'NEW MENU')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>
          </div>
        )}

        {/* Rice */}
        {mainMenu === 'Rice' && (
          <div>
            {/* 白飯 RICE */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>白飯 RICE</h1>
              </div>

              <div className="category-img-container">
                <img src="/images/Rice.jpg" alt="" className="category-img" />
              </div>

              {products
                .filter((product) => product.category === 'RICE')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>
          </div>
        )}

        {/* Noodles */}
        {mainMenu === 'Noodles' && (
          <div>
            {/* 麵類 NOODLE */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>麵類 NOODLE</h1>
              </div>

              <div className="category-img-container">
                <img src="/images/Noodle.jpg" alt="" className="category-img" />
              </div>

              {products
                .filter((product) => product.category === 'NOODLE')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>
          </div>
        )}

        {/* Soup */}
        {mainMenu === 'Soup' && (
          <div>
            {/* 湯類 SOUP */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>湯類 SOUP</h1>
              </div>

              <div className="category-img-container">
                <img src="/images/Soup.jpg" alt="" className="category-img" />
              </div>

              {products
                .filter((product) => product.category === 'SOUP')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>
          </div>
        )}

        {/* Drinks */}
        {mainMenu === 'Drinks' && (
          <div>
            {/* 紅綠茶類 BLACK TEA/GREEN TEA */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>紅綠茶類 BLACK TEA/GREEN TEA</h1>
              </div>

              <div className="category-img-container">
                <img
                  src="/images/BlackTeaGreenTea.jpg"
                  alt=""
                  className="category-img"
                />
              </div>

              {products
                .filter((product) => product.category === 'BLACK TEA/GREEN TEA')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>

            {/* 奶茶/奶綠類 MILK TEA/MILK GREEN TEA */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>奶茶/奶綠類 MILK TEA/MILK GREEN TEA</h1>
              </div>

              <div className="category-img-container">
                <img
                  src="/images/MilkTeaMilkGreenTea.jpg"
                  alt=""
                  className="category-img"
                />
              </div>

              {products
                .filter(
                  (product) => product.category === 'MILK TEA/MILK GREEN TEA'
                )
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>

            {/* 冰沙類 SLUSH */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>冰沙類 SLUSH</h1>
              </div>

              {products
                .filter((product) => product.category === 'SLUSH')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>

            {/* 多多系列 YOGURT SPECIAL */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>多多系列 YOGURT SPECIAL</h1>
              </div>

              {products
                .filter((product) => product.category === 'YOGURT SPECIAL')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>

            {/* 台灣飲品 TAIWANESE DRINK */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>台灣飲品 TAIWANESE DRINK</h1>
              </div>

              <div className="category-img-container">
                <img
                  src="/images/TaiwaneseDrink.jpg"
                  alt=""
                  className="category-img"
                />
              </div>

              {products
                .filter((product) => product.category === 'TAIWANESE DRINK')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>

            {/* 新鮮果汁 FRESH JUICE */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>新鮮果汁 FRESH JUICE</h1>
              </div>

              <div className="category-img-container">
                <img
                  src="/images/FreshJuice.jpg"
                  alt=""
                  className="category-img"
                />
              </div>

              {products
                .filter((product) => product.category === 'FRESH JUICE')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>

            {/* 新鮮牛奶 FRESH MILK */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>新鮮牛奶 FRESH MILK</h1>
              </div>

              {products
                .filter((product) => product.category === 'FRESH MILK')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>

            {/* 啤酒 BEER */}
            <div className="grid-col2">
              <div className="category-title">
                <h1>啤酒 BEER</h1>
              </div>

              <div className="category-img-container">
                <img src="/images/Beer.jpg" alt="" className="category-img" />
              </div>

              {products
                .filter((product) => product.category === 'BEER')
                .sort((a, b) => a.price - b.price)
                .map((product) => (
                  <Product key={product._id} product={product}></Product>
                ))}
            </div>
          </div>
        )}

        {/* MealInBigBox */}
        {mainMenu === 'MealInBigBox' && (
          <div>
            <div
              className="card card-body"
              style={{
                fontSize: '1.2rem',
                backgroundColor: 'pink',
              }}
            >
              <div className="row center">
                大盒美味的合菜系列（含葷素兩種） 每菜$23 <br />{' '}
                因為準備時間長，請提前2小時預訂。
              </div>
            </div>
            <div
              className="card card-body"
              style={{
                fontSize: '1.2rem',
                backgroundColor: 'pink',
              }}
            >
              <div className="row center">
                Delicious meal in big box(Both Vegetarian only and with meat),
                $23 per box <br /> Please order 2 hours ahead, need more time to
                prepare. Thank you!
              </div>
            </div>
            {/* 合菜系列A  Meal in Box */}
            <div id="meal-in-box">
              <h1>精緻合菜 A系列</h1>
            </div>

            <div className="grid-col2" style={{ marginTop: '1rem' }}>
              <div className="card card-body">
                <div className="category-title">
                  <h2 className="text-center">葷菜 Meal</h2>
                </div>

                {products
                  .filter((product) => product.category === 'A-MealInBox')
                  .sort((a, b) => a.price - b.price)
                  .map((product) => (
                    <Product key={product._id} product={product}></Product>
                  ))}
              </div>

              <div className="card card-body">
                <div className="category-title">
                  <h2 className="text-center">素菜 Vegetarian only</h2>
                </div>

                {products
                  .filter((product) => product.category === 'VA-MealInBox')
                  .sort((a, b) => a.price - b.price)
                  .map((product) => (
                    <Product key={product._id} product={product}></Product>
                  ))}
              </div>
            </div>
            <div className="mealinbox-img-container">
              <img
                src="/images/A_VA_MealInBox.jpg"
                alt=""
                className="mealinbox-img"
              />
            </div>

            {/* 合菜系列B  Meal in Box */}
            <div id="meal-in-box">
              <h1>精緻合菜 B系列</h1>
            </div>
            <div className="grid-col2" style={{ marginTop: '1rem' }}>
              <div className="card card-body">
                <div className="category-title">
                  <h2 className="text-center">葷菜 Meal</h2>
                </div>

                {products
                  .filter((product) => product.category === 'B-MealInBox')
                  .sort((a, b) => a.price - b.price)
                  .map((product) => (
                    <Product key={product._id} product={product}></Product>
                  ))}
              </div>

              <div className="card card-body">
                <div className="category-title">
                  <h2 className="text-center">素菜 Vegetarian only</h2>
                </div>

                {products
                  .filter((product) => product.category === 'VB-MealInBox')
                  .sort((a, b) => a.price - b.price)
                  .map((product) => (
                    <Product key={product._id} product={product}></Product>
                  ))}
              </div>
            </div>

            <div className="mealinbox-img-container">
              <img
                src="/images/B_VB_MealInBox.jpg"
                alt=""
                className="mealinbox-img"
              />
            </div>

            {/* 合菜系列C  Meal in Box */}
            <div id="meal-in-box">
              <h1>精緻合菜 C系列</h1>
            </div>
            <div className="grid-col2" style={{ marginTop: '1rem' }}>
              <div className="card card-body">
                <div className="category-title">
                  <h2 className="text-center">葷菜 Meal</h2>
                </div>

                {products
                  .filter((product) => product.category === 'C-MealInBox')
                  .sort((a, b) => a.price - b.price)
                  .map((product) => (
                    <Product key={product._id} product={product}></Product>
                  ))}
              </div>

              <div className="card card-body">
                <div className="category-title">
                  <h2 className="text-center">素菜 Vegetarian only</h2>
                </div>

                {products
                  .filter((product) => product.category === 'VC-MealInBox')
                  .sort((a, b) => a.price - b.price)
                  .map((product) => (
                    <Product key={product._id} product={product}></Product>
                  ))}
              </div>
            </div>
            <div className="mealinbox-img-container">
              <img
                src="/images/C_VC_MealInBox.jpg"
                alt=""
                className="mealinbox-img"
              />
            </div>
          </div>
        )}

        <div className="titleCloseBtn">
          <button
            onClick={() => {
              funcHandle(false)
            }}
          >
            <FaTimes />
          </button>
        </div>
      </div>
    </div>
  )
}

export default SubmenuModal
