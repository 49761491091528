import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createOrder } from '../actions/orderActions'
import { ORDER_CREATE_RESET } from '../constants/orderConstants'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'

//socket.io stuff
//
import socketIOClient from 'socket.io-client'
const ENDPOINT =
  window.location.host.indexOf('localhost') >= 0
    ? 'http://127.0.0.1:5000'
    : window.location.host

const TAX_RATE = 0.05

export default function PlaceOrderScreen(props) {
  const cart = useSelector((state) => state.cart)

  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  const orderCreate = useSelector((state) => state.orderCreate)
  const { loading, success, error, order } = orderCreate

  const toPrice = (num) => Number(num.toFixed(2)) // 5.123 => "5.12" => 5.12
  cart.itemsPrice = toPrice(
    cart.cartItems.reduce((a, c) => a + c.qty * c.priceAfterDiscount, 0)
  )
  cart.shippingPrice = 0
  cart.taxPrice = toPrice(TAX_RATE * cart.itemsPrice)
  cart.totalPrice = cart.itemsPrice + cart.shippingPrice + cart.taxPrice

  //socket.io
  //
  const [socket, setSocket] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (success) {
      //for socket related
      //
      //init socket, and REGISTER socket functions
      if (!socket) {
        //executed once
        //create a socketIO instance
        const sk = socketIOClient(ENDPOINT)
        setSocket(sk)

        //alert(`userInfo.name ${userInfo.name}`)
        //sk will emit 'onLogin' event at init socket
        //'orderPlaced': (order has been placed on PlaceOrderScreen)
        const orderPlacedUser = {
          _id: userInfo._id,
          name: userInfo.name,
          isAdmin: userInfo.isAdmin,
          isSale: userInfo.isSale,
          phone: userInfo.phone,
          //for socket.io
          orderId: order._id,
          orderCancelled: false,
          orderConfirmed: false,
        }
        const orderItems = [...order.orderItems]
        const orderPrices = {
          orderId: order._id,
          itemsPrice: order.itemsPrice,
          shippingPrice: order.shippingPrice,
          taxPrice: order.taxPrice,
          totalPrice: order.totalPrice,
          discount: order.discount,
          paymentMethod: order.paymentMethod,
        }

        sk.emit('orderPlaced', orderPlacedUser, orderItems, orderPrices)
      }

      props.history.push(`/ehome/order/${order._id}`)
      dispatch({ type: ORDER_CREATE_RESET })
    }
  }, [dispatch, order, success, socket])

  //
  const placeOrderHandler = () => {
    dispatch(
      createOrder({
        ...cart,
        orderItems: cart.cartItems,
      })
    )
  }

  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div>
      <div className="row top">
        <div className="col-2">
          <ul>
            <li>
              <div className="card card-body">
                <h2>Payment</h2>
                <p>
                  <strong>Method:</strong> {cart.paymentMethod}
                </p>
              </div>
            </li>

            <li>
              <div className="card card-body">
                <h2>Order Items</h2>
                <ul>
                  {cart.cartItems.map((item) => (
                    <li key={item.product}>
                      <div className="row">
                        <div>
                          <span>
                            {item.image && (
                              <img
                                src={item.image}
                                alt=""
                                className="small"
                              ></img>
                            )}
                          </span>
                          <span className="min-18">{item.name}</span>
                        </div>

                        <div>
                          {item.qty} x ${item.priceAfterDiscount.toFixed(2)} = $
                          {(item.qty * item.priceAfterDiscount).toFixed(2)}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <div className="col-1" style={{ marginTop: '1rem' }}>
          <div
            className="card card-body"
            style={{ backgroundColor: '#ffcd94' }}
          >
            <ul>
              <li>
                <h2>Order Summary</h2>
              </li>
              <li>
                <div className="row">
                  <div>Items</div>
                  <div>${cart.itemsPrice.toFixed(2)}</div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div>Tax</div>
                  <div>${cart.taxPrice.toFixed(2)}</div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div>
                    <strong> Order Total</strong>
                  </div>
                  <div>
                    <strong>${cart.totalPrice.toFixed(2)}</strong>
                  </div>
                </div>
              </li>
            </ul>

            <button
              type="button"
              onClick={placeOrderHandler}
              className="block"
              style={{
                fontSize: '1.2rem',
                backgroundColor: '#c68642',
                marginTop: '1rem',
              }}
              disabled={cart.cartItems.length === 0}
            >
              Place Order
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
