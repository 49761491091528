import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import {
  addToCart,
  addFlavorToCart,
  removeFromCart,
} from '../actions/cartActions'
import MessageBox from '../components/MessageBox'

export default function CartScreen(props) {
  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  const [radio, setRadio] = useState('')
  const [radioEgg, setRadioEgg] = useState('')

  const dispatch = useDispatch()

  const removeFromCartHandler = (id) => {
    // delete action
    dispatch(removeFromCart(id))
  }

  const checkoutHandler = () => {
    //R: if user has not signed in, then sign in
    //      else redirect to PlaceOrderScreen
    props.history.push('/ehome/signin?redirect=placeorder')
  }

  return (
    <div className="row top">
      <div className="col-2">
        <h1>Shopping Cart</h1>

        {cartItems.length === 0 ? (
          <MessageBox>
            Cart is empty. <Link to="/ehome">Go Shopping</Link>
          </MessageBox>
        ) : (
          cartItems.map((item) => (
            //item.product: productId
            <div key={item.product} className="item-container">
              <span className="item-filed1-field2">
                {item.image ? (
                  <img className="item-image small" src={item.image} alt="" />
                ) : (
                  ''
                )}

                {item.name}
              </span>

              {item.category === 'KOREAN STYLE HOT POT' &&
                (item.name === '迷你鍋 Seafood Mini Pot' ||
                  item.name === '海鮮豆腐鍋 Seafood Tofu Pot' ||
                  item.name === '鍋燒麵 Seafood Udon Pot' ||
                  item.name === '番茄海鮮鍋 Seafood Tomato Pot' ||
                  item.name === '泡菜鍋 Seafood Kimchi Pot' ||
                  item.name === '年糕鍋 Sticky Rice Cake Pot' ||
                  item.name === '鮮魚鍋 Seafood Fish Pot' ||
                  item.name === '泡菜湯泡飯 Rice in Kimchi Soup' ||
                  item.name === '石燒拌飯 Bibimbap' ||
                  item.name === '燒肉飯 BBQ Pork with Rice' ||
                  item.name === '辣炒年糕 Spicy Sticky Rice Cake' ||
                  item.name === '烤肉鍋 BBQ Pork+Mini Hot Pot' ||
                  item.name === '部隊鍋 Budae Hot Pot' ||
                  item.name === '部隊鍋(大) Budae Hot Pot(L)') && (
                  <div className="radio-groups">
                    <div className="hot-flavor">
                      <fieldset style={{ padding: '5px' }}>
                        <div>
                          <label className="radio-label">大辣 Very Hot</label>
                          <input
                            type="radio"
                            checked={radio === '大辣 Very Hot'}
                            value="大辣 Very Hot"
                            onChange={(e) => {
                              dispatch(
                                addFlavorToCart(
                                  item.product,
                                  item.qty,
                                  e.target.value
                                )
                              )
                              //setRadio(e.target.value)
                            }}
                          />
                        </div>

                        <div>
                          <label className="radio-label">中辣 Medium</label>
                          <input
                            type="radio"
                            checked={radio === '中辣 Medium'}
                            value="中辣 Medium"
                            onChange={(e) => {
                              dispatch(
                                addFlavorToCart(
                                  item.product,
                                  item.qty,
                                  e.target.value
                                )
                              )
                              //setRadio(e.target.value)
                            }}
                          />
                        </div>

                        <div>
                          <label className="radio-label">小辣 Mild</label>
                          <input
                            type="radio"
                            checked={radio === '小辣 Mild'}
                            value="小辣 Mild"
                            onChange={(e) => {
                              dispatch(
                                addFlavorToCart(
                                  item.product,
                                  item.qty,
                                  e.target.value
                                )
                              )
                              //setRadio(e.target.value)
                            }}
                          />
                        </div>

                        <div>
                          <label className="radio-label">不辣 Non-Spicy</label>
                          <input
                            type="radio"
                            checked={radio === '不辣 Non-Spicy'}
                            value="不辣 Non-Spicy"
                            onChange={(e) => {
                              dispatch(
                                addFlavorToCart(
                                  item.product,
                                  item.qty,
                                  e.target.value
                                )
                              )
                              //setRadio(e.target.value)
                            }}
                          />
                        </div>
                      </fieldset>
                    </div>
                  </div>
                )}

              {item.category === 'VEGETARIAN POT' &&
                (item.name === '青菜豆腐鍋 Vegetable Tofu Pot' ||
                  item.name === '素年糕鍋 Vegetarian Sticky Rice Cake Pot' ||
                  item.name === '三姑營養素食鍋 Vegetarian Mushroom Pot' ||
                  item.name === '素泡菜鍋 Vegetarian Kimchi Hot Pot' ||
                  item.name ===
                    '素泡菜湯泡飯 Vegetarian Rice in Kimchi Soup') && (
                  <div className="radio-groups">
                    <div className="hot-flavor">
                      <fieldset style={{ padding: '5px' }}>
                        <legend
                          style={{ paddingBottom: '15px', color: 'blue' }}
                        >
                          {' '}
                          1. Egg or No Egg ?
                        </legend>
                        <div>
                          <label className="radio-label">不加蛋 No Egg</label>
                          <input
                            type="radio"
                            name="egg"
                            value="不加蛋 No Egg"
                            onChange={(e) => {
                              setRadioEgg(e.target.value)
                            }}
                          />
                        </div>

                        <div>
                          <label className="radio-label">加蛋 With Egg</label>
                          <input
                            type="radio"
                            name="egg"
                            checked={radioEgg === '加蛋 With Egg'}
                            value="加蛋 With Egg"
                            onChange={(e) => {
                              setRadioEgg(e.target.value)
                            }}
                          />
                        </div>
                      </fieldset>
                    </div>

                    <div className="hot-flavor">
                      <fieldset style={{ padding: '5px' }}>
                        <legend
                          style={{ paddingBottom: '15px', color: 'blue' }}
                        >
                          2. Hot Flavor ?
                        </legend>
                        <div>
                          <label className="radio-label">大辣 Very Hot</label>
                          <input
                            type="radio"
                            name="hot"
                            checked={radio === '大辣 Very Hot'}
                            value="大辣 Very Hot"
                            onChange={(e) => {
                              dispatch(
                                addFlavorToCart(
                                  item.product,
                                  item.qty,
                                  radioEgg + ' ' + e.target.value
                                )
                              )
                            }}
                          />
                        </div>

                        <div>
                          <label className="radio-label">中辣 Medium</label>
                          <input
                            type="radio"
                            name="hot"
                            checked={radio === '中辣 Medium'}
                            value="中辣 Medium"
                            onChange={(e) => {
                              dispatch(
                                addFlavorToCart(
                                  item.product,
                                  item.qty,
                                  radioEgg + ' ' + e.target.value
                                )
                              )
                            }}
                          />
                        </div>

                        <div>
                          <label className="radio-label">小辣 Mild</label>
                          <input
                            type="radio"
                            name="hot"
                            checked={radio === '小辣 Mild'}
                            value="小辣 Mild"
                            onChange={(e) => {
                              dispatch(
                                addFlavorToCart(
                                  item.product,
                                  item.qty,
                                  radioEgg + ' ' + e.target.value
                                )
                              )
                            }}
                          />
                        </div>

                        <div>
                          <label className="radio-label">不辣 Non-Spicy</label>
                          <input
                            type="radio"
                            name="hot"
                            checked={radio === '不辣 Non-Spicy'}
                            value="不辣 Non-Spicy"
                            onChange={(e) => {
                              dispatch(
                                addFlavorToCart(
                                  item.product,
                                  item.qty,
                                  radioEgg + ' ' + e.target.value
                                )
                              )
                            }}
                          />
                        </div>
                      </fieldset>
                    </div>
                  </div>
                )}

              {item.category === 'VEGETARIAN POT' &&
                (item.name ===
                  '素肉羹冬粉/麵 Vegetarian Gluten Pork Thick & Noodles' ||
                  item.name === '素奶香豆腐鍋 Vegetarian Milk Pot' ||
                  item.name ===
                    '素辣炒年糕 Vegetarian Spicy Sticky Rice Cake' ||
                  item.name ===
                    '素麻油雞麵線 Vegetarian Sesame oil chicken Soup & Fine Noodles' ||
                  item.name === '素紅燒面 Braised Tomato Soup with Noodles' ||
                  item.name ===
                    '素紅燒冬粉 Braised Tomato Soup with Vermicelli' ||
                  item.name === '素養生鍋 Herbal Vegetarian Pot') && (
                  <div className="radio-groups">
                    <div className="hot-flavor">
                      <fieldset style={{ padding: '5px' }}>
                        <div>
                          <label className="radio-label">大辣 Very Hot</label>
                          <input
                            type="radio"
                            checked={radio === '大辣 Very Hot'}
                            value="大辣 Very Hot"
                            onChange={(e) => {
                              dispatch(
                                addFlavorToCart(
                                  item.product,
                                  item.qty,
                                  e.target.value
                                )
                              )
                              //setRadio(e.target.value)
                            }}
                          />
                        </div>

                        <div>
                          <label className="radio-label">中辣 Medium</label>
                          <input
                            type="radio"
                            checked={radio === '中辣 Medium'}
                            value="中辣 Medium"
                            onChange={(e) => {
                              dispatch(
                                addFlavorToCart(
                                  item.product,
                                  item.qty,
                                  e.target.value
                                )
                              )
                              //setRadio(e.target.value)
                            }}
                          />
                        </div>

                        <div>
                          <label className="radio-label">小辣 Mild</label>
                          <input
                            type="radio"
                            checked={radio === '小辣 Mild'}
                            value="小辣 Mild"
                            onChange={(e) => {
                              dispatch(
                                addFlavorToCart(
                                  item.product,
                                  item.qty,
                                  e.target.value
                                )
                              )
                              //setRadio(e.target.value)
                            }}
                          />
                        </div>

                        <div>
                          <label className="radio-label">不辣 Non-Spicy</label>
                          <input
                            type="radio"
                            checked={radio === '不辣 Non-Spicy'}
                            value="不辣 Non-Spicy"
                            onChange={(e) => {
                              dispatch(
                                addFlavorToCart(
                                  item.product,
                                  item.qty,
                                  e.target.value
                                )
                              )
                              //setRadio(e.target.value)
                            }}
                          />
                        </div>
                      </fieldset>
                    </div>
                  </div>
                )}

              <div className="item-price">
                ${item.priceAfterDiscount.toFixed(2)}
              </div>

              <div className="item-qty">
                <select
                  onChange={(e) =>
                    dispatch(
                      addToCart(
                        item.product,
                        Number(e.target.value),
                        item.discountPercent
                      )
                    )
                  }
                >
                  <option value="1" selected={item.qty === 1}>
                    1
                  </option>
                  <option value="2" selected={item.qty === 2}>
                    2
                  </option>
                  <option value="3" selected={item.qty === 3}>
                    3
                  </option>
                </select>
              </div>

              <div>
                <button
                  className="item-button"
                  onClick={() => removeFromCartHandler(item.product)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))
        )}
      </div>

      <div className="col-1">
        <div className="card card-body">
          <ul>
            <li>
              <h2>
                Subtotal ({cartItems.reduce((a, c) => a + c.qty, 0)} items) : $
                {cartItems
                  .reduce(
                    (a, c) =>
                      a + ((c.price * (100 - c.discountPercent)) / 100) * c.qty,
                    0
                  )
                  .toFixed(2)}
              </h2>
            </li>
            <li>
              <button
                type="button"
                onClick={checkoutHandler}
                className="primary block"
                disabled={cartItems.length === 0}
              >
                Proceed to Checkout
              </button>
            </li>
            <li>
              <Link to="/ehome">
                <button type="button" className="secondary block">
                  ...Continue Shopping...
                </button>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
