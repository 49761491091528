import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { detailsOrder } from '../actions/orderActions'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'

//socket.io stuff
//
import socketIOClient from 'socket.io-client'
import audioFile from './audios/OrderNotificationMusic_User.mp3'

let allMessages = []
//global, used in both useEffect() and event handlers
let sk

const ENDPOINT =
  window.location.host.indexOf('localhost') >= 0
    ? 'http://127.0.0.1:5000'
    : window.location.host

export default function OrderScreen(props) {
  const orderId = props.match.params.id

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  //socket.io
  //
  const [socket, setSocket] = useState(null)
  const uiMessagesRef = useRef(null)
  const [messages, setMessages] = useState([])
  const [playAudio, setPlayAudio] = useState(false)

  const dispatch = useDispatch()

  //for order related
  useEffect(() => {
    if (!order || order._id !== orderId) {
      dispatch(detailsOrder(orderId))
    }
  }, [dispatch, order, orderId])

  //for socket related
  useEffect(() => {
    //for updating and scroll to top of socket UI
    if (uiMessagesRef.current) {
      uiMessagesRef.current.scrollBy({
        top: uiMessagesRef.current.clientHeight,
        left: 0,
        behavior: 'smooth',
      })
    }

    //init socket, and REGISTER socket functions
    if (!socket) {
      //executed once
      //init socketIO instance
      sk = socketIOClient(ENDPOINT)
      //
      setSocket(sk)

      //sk will emit 'onLogin' event at init socket
      //'onLogin': (order has been placed on PlaceOrderScreen)
      sk.emit('onUserLogin', {
        _id: userInfo._id,
        name: userInfo.name,
        isSale: userInfo.isSale,
        phone: userInfo.phone,
        orderId: orderId,
      })

      //sk will REGISTER an event listener on 'message'
      sk.on('message', (data) => {
        if (
          data.name !== 'Greetings from Kitchen' &&
          data.name !== 'Alert' &&
          data.body !== 'Sorry, Your Order is Rejected'
        ) {
          setPlayAudio(true)
          //at end of play, playAudio is set to false
        }

        //
        setMessages({ name: data.name, body: data.body })
      })
    }
  }, [messages, socket, order])
  //  messages for updating uiMessagesRef

  /*   useEffect(() => {
    if (cancelOrder) {
      sk.emit('onOrderCancel', orderId)
      setMessages({ name: 'Hi', body: 'You Just Cancelled Your Order, Bye' })
    }
  }, [cancelOrder]) */

  const onPlayEnded = () => {
    setPlayAudio(false)

    //have server to stamp start time (for cancel order timeout)
    sk.emit('onOrderCancelTimerStart', orderId)
  }

  //////////////////////////////////////////////////////
  // DOM
  //////////////////////////////////////////////////////
  return loading ? (
    <LoadingBox></LoadingBox>
  ) : error ? (
    <MessageBox variant="danger">{error}</MessageBox>
  ) : (
    <div>
      <h1>Your Order Id: {parseInt(order._id.toString().substr(0, 8), 16)}</h1>
      <div>
        {playAudio && (
          <audio
            src={audioFile}
            /* controls */
            autoPlay
            onEnded={onPlayEnded}
            style={{ right: '0' }}
          >
            Your Browser doesn't support the audio tag.
          </audio>
        )}
      </div>

      <div
        className="card card-body"
        style={{ marginLeft: '2rem', backgroundColor: '#baffc9' }}
      >
        <strong>{`${messages.name}: `}</strong> {messages.body}
      </div>

      <div className="row top">
        <div className="col-2">
          <ul>
            <li>
              <div className="card card-body">
                <h2>Order Items</h2>
                <ul>
                  {order.orderItems.map((item) => (
                    <li key={item.product}>
                      <div className="row">
                        <div>
                          <span>
                            {item.image && (
                              <img
                                src={item.image}
                                alt=""
                                className="small"
                              ></img>
                            )}
                          </span>

                          <span className="min-18">{item.name}</span>
                        </div>

                        <div>
                          {item.qty} x ${item.priceAfterDiscount.toFixed(2)} = $
                          {(item.qty * item.priceAfterDiscount).toFixed(2)}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <div className="col-1">
          <div
            className="card card-body"
            style={{
              marginTop: '1.5rem',
              backgroundColor: '#ffcd94',
            }}
          >
            <ul>
              <li>
                <h2>Order Summary</h2>
              </li>
              <li>
                <div className="row">
                  <div>Items</div>
                  <div>${order.itemsPrice.toFixed(2)}</div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div>Tax</div>
                  <div>${order.taxPrice.toFixed(2)}</div>
                </div>
              </li>
              <li>
                <div className="row">
                  <div>
                    <strong> Order Total</strong>
                  </div>
                  <div>
                    <strong>${order.totalPrice.toFixed(2)}</strong>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
