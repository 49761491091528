import {
  EMAIL_SEND_REQUEST,
  EMAIL_SEND_SUCCESS,
  EMAIL_SEND_FAIL,
} from '../constants/emailConstants'

export const sendEmail =
  (
    remindMonth,
    remindDate31,
    selectedHour,
    remindMinute,
    name,
    replyto,
    phone,
    seats,
    note
  ) =>
  async (dispatch) => {
    dispatch({
      type: EMAIL_SEND_REQUEST,
    })
    try {
      dispatch({
        type: EMAIL_SEND_SUCCESS,
        payload: {
          remindMonth,
          remindDate31,
          selectedHour,
          remindMinute,
          name,
          replyto,
          phone,
          seats,
          note,
        },
      })
    } catch (error) {
      dispatch({
        type: EMAIL_SEND_FAIL,
        payload: error.message,
      })
    }
  }
