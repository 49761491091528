import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import Aos from 'aos'
import 'aos/dist/aos.css'

import './About.css'

const About = () => {
  useEffect(() => {
    Aos.init({
      offset: 400, //offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1500, // values from 0 to 3000, with step 50ms
    })
  }, [])

  return (
    <div className="about-card" data-aos="fade-left" id="About">
      <div className="about-image-container">
        <img
          src="/images/RHomePage/About.jpg"
          alt="About"
          className="about-image"
        />
      </div>
      <div>
        <h2>
          About Sasaya Bistro <br /> 全州餐館
        </h2>

        <p>
          We offers an expansive menu of Taiwanese and Chinese cuisine full of
          flavor. For us from Asia, We always miss our hometown food! With a
          wide range of meals including pork, fowl, beef, seafood, and tofu, we
          truly have a delicious option for everyone.
        </p>

        <div className="about-btn">
          <Link to="/ehome"> Our Menu </Link>
        </div>
      </div>
    </div>
  )
}

export default About
