import React from 'react'

import './Banner.css'

function Banner() {
  return (
    <div className="banner-container">
      <img src="/images/BannerImage/banner.jpg" alt="" />
    </div>
  )
}

export default Banner
