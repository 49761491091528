import React from 'react'

import './BusinessHours.css'

const BusinessHours = () => {
  return (
    <div className="busi-container" id="business-hours">
      <div className="busi-hours-logo">
        <img src="/images/RHomePage/OpenHours_logo.jpg" alt="" />
      </div>
      <div>
        <table className="content-table">
          <tbody>
            <tr>
              <td>Mon</td>
              <td>11am–2:30pm</td>
              <td></td>
              <td>5:00pm–9pm</td>
            </tr>
            <tr>
              <td>Tue</td>
              <td>11am–2:30pm</td>
              <td></td>
              <td>5:00pm–9pm</td>
            </tr>
            <tr>
              <td>Wed</td>
              <td>11am–2:30pm</td>
              <td></td>
              <td>5:00pm–9pm</td>
            </tr>
            <tr>
              <td>Thurs</td>
              <td>11am–2:30pm</td>
              <td></td>
              <td>5:00pm–9pm</td>
            </tr>
            <tr>
              <td>Fri</td>
              <td colspan="3">
                11am{' '}
                <hr
                  style={{
                    display: 'inline-block',
                    width: '50%',
                  }}
                />{' '}
                10pm
              </td>
            </tr>
            <tr>
              <td>Sat</td>
              <td colspan="3">
                11am{' '}
                <hr
                  style={{
                    display: 'inline-block',
                    width: '50%',
                  }}
                />{' '}
                10pm
              </td>
            </tr>
            <tr>
              <td>Sun</td>
              <td colspan="3">
                11am{' '}
                <hr
                  style={{
                    display: 'inline-block',
                    width: '50%',
                  }}
                />{' '}
                9pm
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BusinessHours
