import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './Slides.css'
import { multiData } from './data'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'

const PreviousBtn = (props) => {
  /* console.log(props); */
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIos style={{ color: 'blue', fontSize: '30px' }} />
    </div>
  )
}
const NextBtn = (props) => {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIos style={{ color: 'blue', fontSize: '30px' }} />
    </div>
  )
}

const Slides = () => {
  var settings = {
    dots: true,
    infinite: true,
    autoplay: true,

    slidesToShow: 3,
    slidesToScroll: 3,

    responsive: [
      {
        breakpoint: 600,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
      {
        breakpoint: 1200,
        settings: { slidesToShow: 2, slidesToScroll: 2 },
      },
    ],
  }

  return (
    <div style={{ margin: '60px' }} className="carousel">
      <h1>Gallery of Dishes</h1>
      <Slider prevArrow={<PreviousBtn />} nextArrow={<NextBtn />} {...settings}>
        {multiData.map((item, index) => (
          <Card item={item} key={index} />
        ))}
      </Slider>

      <div className="heroBtn-container">
        <button className="heroBtn">
          <Link to="/ehome">Place Order </Link>
        </button>
      </div>
    </div>
  )
}

const Card = ({ item }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        backgroundColor: '#ececbf',
        borderRadius: '10px',
      }}
    >
      <p style={{ height: '25px' }}></p>
      <img
        className="multi__image"
        src={item.img}
        alt="item.title"
        style={{
          width: '100%',
          height: '320px',
          objectFit: 'contain',
          marginBottom: '10px',
          marginTop: '10px',
        }}
      />
      <p
        style={{
          fontFamily: 'Noto Sans TC',
          fontSize: 'clamp(1rem, 0.6747rem + 1.4458vw, 1.6rem)',
          padding: '0px 0',
        }}
      >
        {item.title}
      </p>
    </div>
  )
}

export default Slides
