import React from 'react'

import { IconContext } from 'react-icons'
import { FaBullhorn } from 'react-icons/fa'

import './OnlineOrderBanner.css'

const OnlineOrderBanner = () => {
  return (
    <div className="online-order-banner-container">
      <IconContext.Provider
        value={{ color: '#f9f9f9', size: '3.5rem', marginTop: '2rem' }}
      >
        <FaBullhorn />
      </IconContext.Provider>

      <div className="online-order-banner-content">
        <h1>NEW! Online Ordering From Sasaya Bistro</h1>
        <p>
          Online ordering NOW enabled for pick-up. Just tell us what you want
          and we'll prepare it as fast as we can.
        </p>
        <p>
          All orders are manually confirmed by us directly. Find out in
          real-time when your food is ready.
        </p>
        <p>Watch on-screen when your food is ready for pickup.</p>
      </div>
    </div>
  )
}

export default OnlineOrderBanner
