import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { addToCart } from '../actions/cartActions'

export default function Product(props) {
  const { product } = props

  const [qty, setQty] = useState(1)

  const dispatch = useDispatch()

  const onAddToCart = () => {
    //                                      added V
    dispatch(addToCart(product._id, qty, product.discountPercent))
  }

  return (
    <div key={product._id} className="item-container">
      <span className="item-filed1-field2">
        {product.image ? (
          <img className="item-image small" src={product.image} alt="" />
        ) : (
          ''
        )}

        <Link to="#">{product.name}</Link>
      </span>

      <Link to="#">
        {product.discountPercent > 0 ? (
          <div>
            <div className="item-price through">
              ${product.price.toFixed(2)}
            </div>
            <div className="item-price">
              ${product.priceAfterDiscount.toFixed(2)}
            </div>
          </div>
        ) : (
          <div className="item-price">${product.price.toFixed(2)}</div>
        )}
      </Link>

      <div className="item-qty">
        <select onChange={(e) => setQty(Number(e.target.value))}>
          {[...Array(3).keys()].map((x) => (
            <option key={x + 1} value={x + 1}>
              {x + 1}
            </option>
          ))}
        </select>
      </div>

      <Link to="#">
        <button className="item-button" onClick={onAddToCart}>
          Add to Cart
        </button>
      </Link>
    </div>
  )
}
