import React, { useState } from 'react'

import { BrowserRouter, Switch, Route } from 'react-router-dom'

import AdminRoute from './components/AdminRoute'
import SaleRoute from './components/SaleRoute'
import PrivateRoute from './components/PrivateRoute'

import CartScreen from './screens/CartScreen'
import OrderHistoryScreen from './screens/OrderHistoryScreen'
import OrderScreen from './screens/OrderScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import ProductListScreen from './screens/ProductListScreen'
import ProfileScreen from './screens/ProfileScreen'
import RegisterScreen from './screens/RegisterScreen'
import SigninScreen from './screens/SigninScreen'
import ProductEditScreen from './screens/ProductEditScreen'
import OrderListScreen from './screens/OrderListScreen'
import SupportScreen from './screens/SupportScreen'

import MenuScreen from './screens/MenuScreen/MenuScreen'

import RHomePage from './RHomePage/RHomePage'
import Navbar from './components/Navbar/Navbar'
import Banner from './components/Banner/Banner'

//.env in root
//NODE_ENV=development
//NODE_ENV=production
const App = () => {
  return (
    <BrowserRouter>
      {/* outside of <Switch />, so it's common to all routes */}
      <Navbar />
      <Banner />

      {/* Atten: <Switch></Switch> added */}
      <Route path="/ehome/cart/:id?" component={CartScreen}></Route>

      <Route
        path="/ehome/product/:id/edit"
        component={ProductEditScreen}
        exact
      ></Route>

      <Route path="/ehome/signin" component={SigninScreen}></Route>
      <Route path="/ehome/register" component={RegisterScreen}></Route>

      <Route path="/ehome/placeorder" component={PlaceOrderScreen}></Route>

      <SaleRoute path="/support" component={SupportScreen}></SaleRoute>

      <Route path="/ehome/order/:id" component={OrderScreen}></Route>

      <Route path="/ehome/orderhistory" component={OrderHistoryScreen}></Route>

      <PrivateRoute
        path="/ehome/profile"
        component={ProfileScreen}
      ></PrivateRoute>

      <AdminRoute
        path="/ehome/productlist"
        component={ProductListScreen}
      ></AdminRoute>
      <AdminRoute
        path="/ehome/orderlist"
        component={OrderListScreen}
      ></AdminRoute>

      {/* Atten:  "/ehome" pointing to ecommerce-homepage */}
      <Route path="/ehome" component={MenuScreen} exact></Route>

      {/* Atten:  "/" pointing to Landingpage/Homepage  */}
      <Route path="/" component={RHomePage} exact></Route>
    </BrowserRouter>
  )
}

export default App
