import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { signout } from '../../actions/userActions'

import './Navbar.css'

import { AiOutlineHome } from 'react-icons/ai'
import { FiPhone } from 'react-icons/fi'

const Navbar = () => {
  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  const dispatch = useDispatch()
  const signoutHandler = () => {
    dispatch(signout())

    //added from proshop
    document.location.href = '/ehome/signin'
  }

  return (
    <div className="nav-ehome-grid">
      <div className="nav-home">
        <a href="/">
          <AiOutlineHome color="#E0E0E0" size="2rem" />
        </a>
      </div>

      {/* <div className="nav-spacer"></div> */}

      <div id="nav-phone">
        <span>
          <FiPhone color="#E0E0E0" />
        </span>
        604 {String.fromCharCode(183)} 433 {String.fromCharCode(183)} 3652
      </div>

      <div>
        <Link to="/ehome">Menu</Link>
      </div>

      <div className="nav-cart-badge">
        <Link to="/ehome/cart">Cart</Link>

        {cartItems.length > 0 && (
          <Link to="/ehome/cart" className="badge">
            {cartItems.length}
          </Link>
        )}
      </div>

      {/* general user (~Sale, ~Admin) */}
      {userInfo ? (
        <div className="dropdown">
          <Link to="#">
            <span className="nav-user"> {userInfo.name} </span>
            <i className="fa fa-caret-down"></i>
          </Link>
          <ul className="dropdown-content">
            <li>
              <Link to="/ehome/profile">User Profile</Link>
            </li>
            <li>
              <Link to="/ehome/orderhistory">Order History</Link>
            </li>
            <li>
              <Link to="#signout" onClick={signoutHandler}>
                Sign Out
              </Link>
            </li>
          </ul>
        </div>
      ) : (
        <div>
          <Link to="/ehome/signin">Sign In</Link>
        </div>
      )}

      <div style={{ whiteSpace: 'nowrap' }}>
        {userInfo && userInfo.isSale && (
          <Link to="/support">Online Orders</Link>
        )}
      </div>

      {userInfo && userInfo.isAdmin && (
        <div className="dropdown">
          <Link to="#admin">
            Panel <i className="fa fa-caret-down"></i>
          </Link>
          <ul className="dropdown-content">
            <li>
              <Link to="/ehome/productlist">Products</Link>
            </li>
            <li>
              <Link to="/ehome/orderlist">Orders History</Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}

export default Navbar
