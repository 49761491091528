import React from 'react'
import { Link } from 'react-router-dom'

import './Introduction.css'

const Introduction = () => {
  return (
    <>
      <div className="intro-container" id="HomeTop">
        <h1>Food That You Can't Resist</h1>

        <div className="intro-items">
          <h2>Enjoy The Marvelous Taste</h2>
          <p>
            Extensive menu of Taiwanese and Chinese cuisine full of flavor. Miss
            our hometown food? We offer a wide range of meals including pork,
            fowl, beef, seafood, and tofu, a truly delicious option for
            everyone.
          </p>

          <button>
            <Link to="/ehome"> Food Menu </Link>
          </button>
        </div>
      </div>
    </>
  )
}

export default Introduction
