import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { listProducts } from '../../actions/productActions'
import LoadingBox from '../../components/LoadingBox'
import MessageBox from '../../components/MessageBox'

import './MenuScreen.css'
import { categoryData } from './categoryData'
import SubmenuModal from '../../components/Modal/SubmenuModal'

export default function MenuScreen(props) {
  const [mainMenu, setMainMenu] = useState('')

  const dispatch = useDispatch()

  const productList = useSelector((state) => state.productList)
  const { loading, error, products } = productList

  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    dispatch(listProducts())
  }, [dispatch])

  return (
    <div className="menuscreen">
      {/* making sure products (all food items) are loaded from database */}
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <div>
          {/* discount info */}
          {/*  <div style={{ textAlign: 'center', color: '#fa23af' }}>
            <h2>特別優惠: 週一到週四自取，10%優惠(不含便當、合菜系列)</h2>
            <h2>Special for On-line Orders (Mon-Thursday): 10% discount </h2>
            <p>(not including: Meal in Big Box or Lunch Box) </p>
          </div> */}

          {/* Apply discount policy */}
          {products.forEach((product) => {
            //discount policy
            //R: we don't modify product.discountPercent in MongoDB
            //Sunday - Saturday : 0 - 6
            const today = new Date()
            const startDate = new Date(product.discountStartDate)
            const endDate = new Date(product.discountEndDate)
            //alert(`startDate ${startDate}`)
            console.log('today.getDay() ', today.getDay())
            if (
              today.getTime() < startDate.getTime() ||
              today.getTime() > endDate.getTime() ||
              today.getDay() === 0 ||
              today.getDay() === 5 ||
              today.getDay() === 6
            ) {
              //reset discountPercent for non-special date
              product.discountPercent = 0
            }
            //R calculated on-fly here
            product.priceAfterDiscount =
              (product.price * (100 - product.discountPercent)) / 100
          })}

          {/* mainMenu selection  */}
          <div className="menu-tabs">
            {categoryData.map((cat, index) => (
              <button
                className="main-menu"
                key={index}
                onClick={() => {
                  setMainMenu(cat.mainCat)
                  setOpenModal(true)
                }}
              >
                {cat.titleC} <br /> {cat.titleE}
              </button>
            ))}
          </div>

          {/* Based on above mainMenu selection,  load its subMenu and items */}
          {openModal && (
            <SubmenuModal
              products={products}
              mainMenu={mainMenu}
              funcHandle={setOpenModal}
            />
          )}
        </div>
      )}
    </div>
  )
}
