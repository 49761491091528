import {
  CART_ADD_ITEM,
  CART_ADD_FLAVOR,
  CART_EMPTY,
  CART_REMOVE_ITEM,
  CART_SAVE_PAYMENT_METHOD,
} from '../constants/cartConstants'

export const cartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload
      const existItem = state.cartItems.find((x) => x.product === item.product)
      //if an item is already in cart, then replace (update qty)
      if (existItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.product === existItem.product ? item : x
          ),
        }
      } else {
        return { ...state, cartItems: [...state.cartItems, item] }
      }

    case CART_ADD_FLAVOR:
      const item2 = action.payload
      const existItem2 = state.cartItems.find(
        (x) => x.product === item2.product
      )
      //if an item is already in cart, then update name with flavor, qty (1)
      if (existItem2) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.product === existItem2.product ? item2 : x
          ),
        }
      } else {
        return { ...state, cartItems: [...state.cartItems, item2] }
      }

    case CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x.product !== action.payload), //action.payload is productId for CART_REMOVE_ITEM
      }
    case CART_SAVE_PAYMENT_METHOD:
      return { ...state, paymentMethod: action.payload }
    case CART_EMPTY:
      return { ...state, cartItems: [] }
    default:
      return state
  }
}
