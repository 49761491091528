import React from 'react'

import SpecialDiscounts from './SpecialDiscounts/SpecialDiscounts'
import Introduction from './Introduction/Introduction'
import Slides from './Slides/Slides'
import ReserveTable from './ReserveTable/ReserveTable'
import About from './About/About'
import MapMe from './MapMe/MapMe'
import BusinessHours from './BusinessHours/BusinessHours'
import OnlineOrderBanner from './OnlineOrderBanner/OnlineOrderBanner'
import BNavbar from './BNavbar/BNavbar'
import SasayaBistroBar from './SasayaBistroBar/SasayaBistroBar'

/* Atten: added, will be loaded through Route "/"  */
/* RHomePage - "/"" */
const RHomePage = () => {
  return (
    <div>
      {/* <SasayaBistroBar /> */}
      {/* <SpecialDiscounts /> */}
      <Introduction />
      <Slides />
      <ReserveTable />
      <About />
      <BusinessHours />
      <OnlineOrderBanner />
      <MapMe />
      <BNavbar />
    </div>
  )
}

export default RHomePage
