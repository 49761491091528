import React from 'react'

import './MapMe.css'

const MapMe = () => {
  const MAP_URL =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2606.217691459626!2d-122.99041984888255!3d49.21539848335532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486764126617467%3A0xfa973132f8db03ee!2s7538%20Royal%20Oak%20Ave%2C%20Burnaby%2C%20BC%20V5J%204K1!5e0!3m2!1sen!2sca!4v1628696468066!5m2!1sen!2sca'
  const iframeStyle = {
    width: '85vw',
    height: '50vh',
    border: '0',
    loading: 'lazy',
  }

  return (
    <div className="map-container" id="business-address">
      <h2>Address: 7538 Royal Oak Ave, Burnaby, BC V5J 4K1</h2>
      <div>
        <iframe
          title="google-map-iframe"
          src={MAP_URL}
          style={iframeStyle}
        ></iframe>
      </div>
    </div>
  )
}

export default MapMe
