import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import useSound from 'use-sound'

import MessageBox from '../components/MessageBox'

import socketIOClient from 'socket.io-client'
import audioFile from './audios/OrderNotificationMusic_Kitchen3.mp3'
import audioFile2 from './audios/OrderNotificationMusic_Kitchen3.mp3'
import mouseDownFile from './audios/mouse_down.mp3'
import mouseUpFile from './audios/mouse_up.mp3'

let wakeLock = null
//local recording of all incoming orders, each order is a set of {user, orderItems, orderPrices}
//{user, orderItems, orderPrices},...{user, orderItems, orderPrices}
let allRingUsers = []
let allRingOrderItems = []
let allRingOrderPrices = []

const ENDPOINT =
  window.location.host.indexOf('localhost') >= 0
    ? 'http://127.0.0.1:5000'
    : window.location.host

export default function SupportScreen() {
  const [socket, setSocket] = useState(null)

  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  //for new order
  const [playAudio, setPlayAudio] = useState(false)
  //for confirming order
  const [playAudio2, setPlayAudio2] = useState(false)
  //index of selected order for order array in the left column
  const [selectedIndex, setSelectedIndex] = useState(0)
  //the user of incoming order, for UI update purpose
  const [newUser, setNewUser] = useState({})
  //
  const [orderStatUpdate, setOrderStatUpdate] = useState(false)

  //persisting ticked-state(s) of checkbox(es) for socketIO Users
  //-------------------------------------------------------------
  //R: how to fill a variable length array
  const [checkedState, setCheckedState] = useState(new Array(100).fill(false))

  //for new order
  const [play1, { stop: stop1 }] = useSound(audioFile)
  //for confirm order
  const [play2, { stop: stop2 }] = useSound(audioFile2, { volume: 0.9 })
  //for Button UI with sound
  const [playMouseDown] = useSound(mouseDownFile, { volume: 1 })
  const [playMouseUp] = useSound(mouseUpFile, { volume: 1 })

  //
  const [prepBtnDisabled, setPrepBtnDisabled] = useState(false)

  //load initial value if data exist
  useEffect(() => {
    //load data from local storage (local memory for browser-refresh)
    let data = localStorage.getItem('checkedState')
    if (data) {
      setCheckedState(JSON.parse(data))
    }
    //get ring orders from local storage
    data = localStorage.getItem('allRingUsers')
    if (data) {
      allRingUsers = JSON.parse(data)
    }
    data = localStorage.getItem('allRingOrderItems')
    if (data) {
      allRingOrderItems = JSON.parse(data)
    }
    data = localStorage.getItem('allRingOrderPrices')
    if (data) {
      allRingOrderPrices = JSON.parse(data)
    }

    //prevent screen from sleep
    const requestWakeLock = async () => {
      try {
        wakeLock = await navigator.wakeLock.request('screen')
        console.log('Screen Wake Lock is active')
      } catch (err) {
        console.error(err)
      }
    }
    //
    requestWakeLock()
    //Elsewhere, perhaps when a user clicks a button...
    //wakeLock.release();
    //wakeLock = null;

    //enable audio (trick)
  }, [])
  //save to local storage whenever checkedState changes
  useEffect(() => {
    localStorage.setItem('checkedState', JSON.stringify(checkedState))
  }, [checkedState])
  //save ring orders to local storage
  useEffect(() => {
    localStorage.setItem('allRingUsers', JSON.stringify(allRingUsers))
    localStorage.setItem('allRingOrderItems', JSON.stringify(allRingOrderItems))
    localStorage.setItem(
      'allRingOrderPrices',
      JSON.stringify(allRingOrderPrices)
    )
  }, [newUser, orderStatUpdate])

  //for socket related
  useEffect(() => {
    //init socket, and REGISTER socket functions
    if (!socket) {
      //executed once
      const sk = socketIOClient(ENDPOINT)
      setSocket(sk)

      //sk will emit admin 'onLogin' event at init socket
      sk.emit('onSaleLogin', {
        _id: userInfo._id,
        name: userInfo.name,
        isSale: userInfo.isSale,
        phone: userInfo.phone,
      })

      //{ updatedUser, order }
      sk.on('ringBell', (user, orderItems, orderPrices) => {
        setPlayAudio(true)

        allRingUsers = [...allRingUsers, user]
        allRingOrderItems = [...allRingOrderItems, orderItems]
        allRingOrderPrices = [...allRingOrderPrices, orderPrices]

        //for UI update purpose
        setNewUser(user)
        //
        setOrderStatUpdate(false)
        //
        //setPrepBtnDisabled(false)
      })

      //user is online, update status
      sk.on('online', (user) => {
        //allRingUsers [{order-1-user1}, ..., {order-x-user1}, ..., {order-n-user}]
        //so user1 might have multiple orders
        //forEach can find/replace multiple occurrences
        allRingUsers.forEach((item, index) => {
          if (item._id === user._id) {
            allRingUsers[index].online = true
          }
        })
        //alert(`${user.name} is online`)
      })

      //user is offline, update status
      sk.on('offline', (user) => {
        allRingUsers.forEach((item, index) => {
          if (item._id === user._id) {
            allRingUsers[index].online = false
          }
        })
        //alert(`${user.name} is offline`)
      })

      sk.on('orderConfirmed', (orderId) => {
        allRingUsers.forEach((item, index) => {
          if (item.orderId === orderId) {
            allRingUsers[index].orderConfirmed = true
          }
        })

        setOrderStatUpdate(true)
        setPlayAudio2(true)
      })
      //
      sk.on('orderCancelled', (orderId) => {
        allRingUsers.forEach((item, index) => {
          if (item.orderId === orderId) {
            allRingUsers[index].orderCancelled = true
          }
        })

        setOrderStatUpdate(true)
      })
    }
  }, [socket])

  //socket, admin send message to a user
  const prepTimeSubmitHandler = (e, prepTime) => {
    //_id   receiver id
    socket.emit('onMessage', {
      body: prepTime,
      name: userInfo.name,
      isSale: userInfo.isSale,
      _id: allRingUsers[selectedIndex]._id,
    })

    //
    stop1()

    //
    setPrepBtnDisabled(true)
    setTimeout(() => {
      setPrepBtnDisabled(false)
    }, 30000)
  }

  const handleCheckboxChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    )

    setCheckedState(updatedCheckedState)
  }

  const orderClickHandler = (e, index) => {
    setSelectedIndex(index)
  }

  const clearOrdersHandler = () => {
    const answer = window.confirm('Are you sure to clear all orders ?')
    if (answer) {
      //clear memory
      allRingUsers = []
      allRingOrderItems = []
      allRingOrderPrices = []

      //clear storage
      localStorage.setItem('allRingUsers', JSON.stringify(allRingUsers))
      localStorage.setItem(
        'allRingOrderItems',
        JSON.stringify(allRingOrderItems)
      )
      localStorage.setItem(
        'allRingOrderPrices',
        JSON.stringify(allRingOrderPrices)
      )

      //refresh this page
      window.location.reload()
    }
  }

  const playAudioF1 = () => {
    setPlayAudio(false)
    play1()
  }

  const playAudioF2 = () => {
    setPlayAudio2(false)
    play2()
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1>Sasaya Kitchen</h1>
        <button
          className="prepTimeBtn"
          onClick={() => {
            stop1()
            stop2()
          }}
          onMouseDown={playMouseDown}
          onMouseUp={playMouseUp}
        >
          Mute
        </button>
      </div>

      <div>
        {/* sound-notify store of a new order */}
        {playAudio && playAudioF1()}

        {/* sound-notify store after User Confirm order */}
        {playAudio2 && playAudioF2()}
      </div>

      {/* display new order info */}
      <div>
        {playAudio && (
          <div>
            <h1>new order from {newUser.name}</h1>
            <h1>{newUser.phone}</h1>
          </div>
        )}
      </div>

      <div className="row top">
        {/* left column */}
        <div className="col-1">
          {allRingUsers.length === 0 ? (
            <MessageBox>no Order so far </MessageBox>
          ) : (
            <div>
              {allRingUsers.map((user, index) => {
                return (
                  <div key={user._id} className="support-users">
                    <button
                      className={index === selectedIndex ? 'selected-user' : ''}
                      type="button"
                      onClick={(e) => orderClickHandler(e, index)}
                    >
                      Order {index + 1}
                    </button>

                    <div>
                      {user.orderConfirmed && <strong>Confirmed</strong>}
                      {user.orderCancelled && <strong>Cancelled</strong>}
                    </div>

                    {/* {user.online ? (
                        <span className="online-user"></span>
                      ) : (
                        <span className="offline-user"></span>
                      )} */}

                    <div>
                      <input
                        type="checkbox"
                        id={`orderCheckBox-${index}`}
                        checked={checkedState[index]}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          )}

          <div>
            <button
              className="secondary"
              style={{
                position: 'fixed',
                left: '0',
                bottom: '0',
                width: '12rem',
                marginLeft: '1rem',
                textAlign: 'center',
              }}
              onClick={clearOrdersHandler}
            >
              Clear All Orders
            </button>
          </div>
        </div>

        {/* right column */}
        <div className="col-3 support-messages">
          {allRingUsers.length > 0 && selectedIndex >= 0 && (
            <div>
              <div className="row">
                <h2 style={{ padding: '0 2px 0 8px' }}>
                  Order {selectedIndex + 1} from{' '}
                  {allRingUsers[selectedIndex].name}{' '}
                </h2>
                <h2 style={{ padding: '0 2px 0 8px' }}>
                  {allRingUsers[selectedIndex].phone}
                </h2>
                <p style={{ paddingLeft: '8px' }}>
                  Order ID:{' '}
                  {allRingOrderPrices[selectedIndex] &&
                    parseInt(
                      allRingOrderPrices[selectedIndex].orderId
                        .toString()
                        .substr(0, 8),
                      16
                    )}
                </p>
              </div>

              <div className="row">
                <button
                  className="prepTimeBtn"
                  disabled={prepBtnDisabled}
                  onClick={(e) =>
                    prepTimeSubmitHandler(
                      e,
                      'Will be Ready in 20min. You may cancel within 3 minutes through your Order History'
                    )
                  }
                  onMouseDown={playMouseDown}
                  onMouseUp={playMouseUp}
                >
                  20 min
                </button>
                <button
                  className="prepTimeBtn"
                  disabled={prepBtnDisabled}
                  onClick={(e) =>
                    prepTimeSubmitHandler(
                      e,
                      'will be ready in 30min. You may cancel within 3 minutes through your Order History'
                    )
                  }
                  onMouseDown={playMouseDown}
                  onMouseUp={playMouseUp}
                >
                  30 min
                </button>
                <button
                  className="prepTimeBtn"
                  disabled={prepBtnDisabled}
                  onClick={(e) =>
                    prepTimeSubmitHandler(
                      e,
                      'will be ready in 45min. You may cancel within 3 minutes through your Order History'
                    )
                  }
                  onMouseDown={playMouseDown}
                  onMouseUp={playMouseUp}
                >
                  45 min
                </button>
                <button
                  className="prepTimeBtn"
                  disabled={prepBtnDisabled}
                  onClick={(e) =>
                    prepTimeSubmitHandler(
                      e,
                      'will be ready in 1 hour. You may cancel within 3 minutes through your Order History'
                    )
                  }
                  onMouseDown={playMouseDown}
                  onMouseUp={playMouseUp}
                >
                  1 hour
                </button>

                <button
                  className="prepTimeBtn"
                  disabled={prepBtnDisabled}
                  onClick={(e) =>
                    prepTimeSubmitHandler(e, 'Sorry, Your Order is Rejected')
                  }
                  onMouseDown={playMouseDown}
                  onMouseUp={playMouseUp}
                  style={{
                    borderColor: 'red',
                    borderWidth: 'thick',
                    backgroundColor: 'pink',
                  }}
                >
                  <strong>Reject</strong>
                </button>
              </div>
            </div>

            //
          )}

          {allRingOrderItems.length > 0 && (
            <div>
              <hr style={{ margin: '1rem 0' }} />

              <h2>Order Items</h2>
              <ul>
                {allRingOrderItems[selectedIndex] &&
                  allRingOrderItems[selectedIndex].map((item) => (
                    <li key={item.product}>
                      <div className="row">
                        <div>
                          <span>
                            {item.image && (
                              <img
                                src={item.image}
                                alt=""
                                className="small"
                              ></img>
                            )}
                          </span>

                          <span className="min-18">{item.name}</span>
                        </div>

                        <div>
                          {item.qty} x ${item.priceAfterDiscount} = $
                          {item.qty * item.priceAfterDiscount}
                        </div>
                      </div>
                    </li>
                  ))}

                <li>
                  <h2>Order Summary</h2>
                </li>
                <li>
                  <div className="row">
                    <div>Items</div>
                    <div>
                      $
                      {allRingOrderPrices[selectedIndex] &&
                        allRingOrderPrices[selectedIndex].itemsPrice.toFixed(2)}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div>Tax</div>
                    <div>
                      $
                      {allRingOrderPrices[selectedIndex] &&
                        allRingOrderPrices[selectedIndex].taxPrice.toFixed(2)}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="row">
                    <div>
                      <strong> Order Total</strong>
                    </div>
                    <div>
                      <strong>
                        $
                        {allRingOrderPrices[selectedIndex] &&
                          allRingOrderPrices[selectedIndex].totalPrice.toFixed(
                            2
                          )}
                      </strong>
                    </div>
                  </div>
                </li>
                <li>
                  <h1
                    style={{
                      textDecoration: 'underline wavy brown',
                      color: 'purple',
                    }}
                  >
                    {allRingOrderPrices[selectedIndex] &&
                      allRingOrderPrices[selectedIndex].paymentMethod}
                  </h1>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
