import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { signin } from '../actions/userActions'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'

export default function SigninScreen(props) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [phone, setPhone] = useState('')

    //https://www.sasayabistro.ca/ehome/signin?redirect=placeorder
    //https://www.sasayabistro.ca/ehome/signin
    const redirect = props.location.search
        ? props.location.search.split('=')[1]
        : '/ehome'

    const userSignin = useSelector((state) => state.userSignin)
    const { userInfo, loading, error } = userSignin

    const dispatch = useDispatch()

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(signin(email, password, phone))
    }

    useEffect(() => {
        if (userInfo) {
            //alert(`userInfo.isSale ${userInfo.isSale}`)
            //alert(`userInfo.isAdmin ${userInfo.isAdmin}`)

            props.history.push(redirect)
        }
    }, [props.history, redirect, userInfo])

    function formatPhoneNumber(value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value

        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, '')

        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber.length

        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early

        if (phoneNumberLength < 4) return phoneNumber

        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
        }

        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`
    }

    const phoneInputHandler = (e) => {
        // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
        const formattedPhoneNumber = formatPhoneNumber(e.target.value)
        // we'll set the input value using our setInputValue
        setPhone(formattedPhoneNumber)
    }

    return (
        <div>
            <form className="form" onSubmit={submitHandler}>
                <div>
                    <h1>Sign In</h1>
                </div>

                {loading && <LoadingBox></LoadingBox>}
                {error && <MessageBox variant="danger">{error}</MessageBox>}

                <div>
                    <label htmlFor="email">Email address</label>
                    <input
                        type="email"
                        id="email"
                        placeholder="Enter email"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                    ></input>
                </div>

                <div>
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        placeholder="Enter password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                    ></input>
                </div>

                <div>
                    <label htmlFor="phone">Contact Phone</label>
                    <input
                        onChange={(e) => phoneInputHandler(e)}
                        value={phone}
                    ></input>
                </div>

                <div>
                    <label />
                    <button className="primary" type="submit">
                        Sign In
                    </button>
                </div>

                <div>
                    <label />
                    <div>
                        New customer?{' '}
                        <Link to={`/ehome/register?redirect=${redirect}`}>
                            Create your account
                        </Link>
                    </div>
                </div>
            </form>
        </div>
    )
}
