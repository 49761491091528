import React from 'react'

import './SpecialDiscounts.css'

function SpecialDiscounts() {
  return (
    <div className="special-container">
      <div className="special-box">
        <h1>
          Online Order Offer <span> 訂餐優惠</span>
        </h1>
        <h5>特別優惠: 週一到週四自取，10%優惠 (不含便當、合菜系列)</h5>
        <h5>Special for On-line Orders (Mon-Thurs): 10% discount </h5>
        <p>(not including: Meal in Big Box or Lunch Box) </p>
      </div>
    </div>
  )
}

export default SpecialDiscounts
