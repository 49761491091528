import React from 'react'
import { Link } from 'react-router-dom'

/* import { IconContext } from 'react-icons' */
import { FaFacebookSquare } from 'react-icons/fa'
import { AiOutlineHome } from 'react-icons/ai'

import './BNavbar.css'

function bNavbar() {
  const navClickHandler = (e) => {
    e.preventDefault()

    const target = e.target.getAttribute('href')
    const element = document.querySelector(target)

    const location = element.offsetTop

    window.scrollTo({
      left: 0,
      top: location - 40,
    })
  }

  const curYear = new Date().getFullYear()

  return (
    <div className="bnav-footer">
      <div className="bnav-top">
        <div className="bnav-home">
          <a href="#HomeTop">
            <AiOutlineHome color="#E0E0E0" size="2rem" />
          </a>
        </div>

        <div>
          <a href="#About" onClick={navClickHandler}>
            About
          </a>
        </div>

        <div>
          <a href="#business-hours" onClick={navClickHandler}>
            Hours
          </a>
        </div>

        <div>
          <a href="#business-address" onClick={navClickHandler}>
            Location
          </a>
        </div>

        <div>
          <a href="#Reservation" onClick={navClickHandler}>
            Reservation
          </a>
        </div>

        <div className="bnav-facebook">
          <Link
            to={{ pathname: 'https://www.facebook.com/SasayaBistro/' }}
            target="_blank"
          >
            <FaFacebookSquare color="#E0E0E0" size="2rem" />
          </Link>
        </div>
      </div>

      <div className="nav-copyright">
        <p>Copyright &copy; {curYear} All rights reserved</p>
      </div>
    </div>
  )
}

export default bNavbar
