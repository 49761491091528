import multicar01 from '../../images/multicarousel/鹽酥雞.jpg'
import multicar02 from '../../images/multicarousel/燴炒咖哩雞肉.jpg'
import multicar03 from '../../images/multicarousel/素咖哩雞肉.jpg'
import multicar04 from '../../images/multicarousel/鹽酥雞便當.jpg'
import multicar05 from '../../images/multicarousel/滷肉便當.jpg'
import multicar06 from '../../images/multicarousel/素紅糟肉便當.jpg'
import multicar07 from '../../images/multicarousel/素雞卷.jpg'
import multicar08 from '../../images/multicarousel/素炸魚排便當.jpg'
import multicar09 from '../../images/multicarousel/鰻魚飯.jpg'
import multicar10 from '../../images/multicarousel/藥膳排骨.jpg'
import multicar11 from '../../images/multicarousel/特製鹹豬肉.jpg'
import multicar12 from '../../images/multicarousel/三杯杏鮑菇.jpg'
import multicar13 from '../../images/multicarousel/餛飩湯.jpg'
import multicar14 from '../../images/multicarousel/魷魚鬚便當.jpg'
import multicar15 from '../../images/multicarousel/素麻辣紫米臭豆腐.jpg'
import multicar16 from '../../images/multicarousel/海鮮炒烏冬.jpg'
import multicar17 from '../../images/multicarousel/素炒米粉.jpg'
import multicar18 from '../../images/multicarousel/排骨便當.jpg'
import multicar19 from '../../images/multicarousel/回鍋肉.jpg'
import multicar20 from '../../images/multicarousel/咖哩雞肉燴飯.jpg'
import multicar21 from '../../images/multicarousel/韓式燒肉便當.jpg'
import multicar22 from '../../images/multicarousel/素咕咾肉.jpg'
import multicar23 from '../../images/multicarousel/素麻油雞線面.jpg'
import multicar24 from '../../images/multicarousel/養生麻油猴頭菇.jpg'
import multicar25 from '../../images/multicarousel/塔香三杯魷魚鬚.jpg'
import multicar26 from '../../images/multicarousel/葷麻辣大腸臭豆腐.jpg'
import multicar27 from '../../images/multicarousel/梅菜扣肉.jpg'
import multicar28 from '../../images/multicarousel/咖哩海鮮燴炒.jpg'
import multicar29 from '../../images/multicarousel/麻辣鍋.jpg'
import multicar30 from '../../images/multicarousel/素梅干扣肉便當.jpg'
import multicar31 from '../../images/multicarousel/素炸蚵仔便當.jpg'
import multicar32 from '../../images/multicarousel/素沙茶蒟蒻.jpg'
import multicar33 from '../../images/multicarousel/肉羹麵.jpg'
import multicar34 from '../../images/multicarousel/素炸鹽酥雞便當.jpg'
import multicar35 from '../../images/multicarousel/台灣香腸.jpg'
import multicar36 from '../../images/multicarousel/素紅糟肉.jpg'
import multicar37 from '../../images/multicarousel/迷你鍋.jpg'
import multicar38 from '../../images/multicarousel/燴炒黑椒雞肉.jpg'
import multicar39 from '../../images/multicarousel/素炸百頁豆腐便當.jpg'

export const multiData = [
    {
        img: multicar01,
        title: '鹽酥雞',
    },
    {
        img: multicar02,
        title: '燴炒咖哩雞肉',
    },
    {
        img: multicar03,
        title: '素咖哩雞肉',
    },
    {
        img: multicar04,
        title: '鹽酥雞便當',
    },
    {
        img: multicar05,
        title: '滷肉便當',
    },
    {
        img: multicar06,
        title: '素紅糟肉便當',
    },
    {
        img: multicar07,
        title: '素雞卷',
    },
    {
        img: multicar08,
        title: '素炸魚排便當',
    },
    {
        img: multicar09,
        title: '鰻魚飯',
    },
    {
        img: multicar10,
        title: '藥膳排骨',
    },

    /********** another 10 **********/
    {
        img: multicar11,
        title: '特製鹹豬肉',
    },
    {
        img: multicar12,
        title: '三杯杏鮑菇',
    },
    {
        img: multicar13,
        title: '餛飩湯',
    },
    {
        img: multicar14,
        title: '魷魚鬚便當',
    },
    {
        img: multicar15,
        title: '素麻辣紫米臭豆腐',
    },
    {
        img: multicar16,
        title: '海鮮炒烏冬',
    },
    {
        img: multicar17,
        title: '素炒米粉',
    },
    {
        img: multicar18,
        title: '排骨便當',
    },
    {
        img: multicar19,
        title: '回鍋肉',
    },
    {
        img: multicar20,
        title: '咖哩雞肉燴飯',
    },

    /********** another 10 **********/
    {
        img: multicar21,
        title: '韓式燒肉便當',
    },
    {
        img: multicar22,
        title: '素咕咾肉',
    },
    {
        img: multicar23,
        title: '素麻油雞線面',
    },
    {
        img: multicar24,
        title: '養生麻油猴頭菇',
    },
    {
        img: multicar25,
        title: '塔香三杯魷魚鬚',
    },
    {
        img: multicar26,
        title: '葷麻辣大腸臭豆腐',
    },
    {
        img: multicar27,
        title: '梅菜扣肉',
    },
    {
        img: multicar28,
        title: '咖哩海鮮燴炒',
    },
    {
        img: multicar29,
        title: '麻辣鍋',
    },
    {
        img: multicar30,
        title: '素梅干扣肉便當',
    },

    /********** another 10 **********/
    {
        img: multicar31,
        title: '素炸蚵仔便當',
    },
    {
        img: multicar32,
        title: '素沙茶蒟蒻',
    },
    {
        img: multicar33,
        title: '肉羹麵',
    },
    {
        img: multicar34,
        title: '素炸鹽酥雞便當',
    },
    {
        img: multicar35,
        title: '台灣香腸',
    },
    {
        img: multicar36,
        title: '素紅糟肉',
    },
    {
        img: multicar37,
        title: '迷你鍋',
    },
    {
        img: multicar38,
        title: '燴炒黑椒雞肉',
    },

    /********** another 2 **********/
    {
        img: multicar39,
        title: '素炸百頁豆腐便當',
    },
]
