import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import './ReserveTable.css'

import emailjs from 'emailjs-com'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { sendEmail } from '../../actions/emailActions'

/* schedule the reminder */
const schedule = require('node-schedule')

/* rt- reserve table */
const ReserveTable = () => {
  /* for email js */
  const [name, setName] = useState('')
  const [replyto, setReplyto] = useState('')
  const [phone, setPhone] = useState('')
  const [seats, setSeats] = useState('')
  const [note, setNote] = useState('')
  /* for email js */
  const [hour, setHour] = useState('')
  const [minute, setMinute] = useState('')

  /* for calendar and reminder */
  const startDay = new Date()
  const endDay = new Date()
  endDay.setDate(endDay.getDate() + 13)

  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedHour, setSelectedHour] = useState(0)
  const [selectedMinute, setSelectedMinute] = useState(0)

  const dispatch = useDispatch()

  const sendEmailHandler = (e) => {
    e.preventDefault()

    /* copy Date object */
    //need this for schedule on this date
    let remindDate = new Date(selectedDate.getTime())

    //getMonth() is zero based, so +1
    let remindMonth = remindDate.getMonth()
    remindMonth = remindMonth + 1
    let remindDate31 = remindDate.getDate()

    let remindHour = selectedHour - 1 //1 hour ahead of scheduled time
    let remindMinute = selectedMinute
    //update remindDate
    remindDate.setHours(remindHour)
    remindDate.setMinutes(remindMinute)

    /* console.log(selectedDate)
    console.log(remindDate) */

    /* sending email to sasayaE@gmail.com */
    emailjs
      .sendForm(
        'service_fxk654t',
        'template_3i6fonb',
        e.target,
        'user_maDf8IdykJuFsIAPHEQ3h'
      )
      .then(
        (result) => {
          window.alert(
            'Email sending successfully, and \n We will remind you 1 hours ahead of time!'
          )
        },
        (error) => {
          window.alert('Oops, There was a problem in sending email...')
        }
      )

    /* send remind email to both sasayabistroE and customer */
    /* Note: need to use asyn call - use Redux dispatch  */
    schedule.scheduleJob(remindDate, function (e) {
      console.log('schedule job lunched at  ' + new Date())

      dispatch(
        sendEmail(
          remindMonth,
          remindDate31,
          selectedHour,
          remindMinute,
          name,
          replyto,
          phone,
          seats,
          note
        )
      )
    })

    e.target.reset()
  }

  return (
    <div className="rt-banner" id="Reservation">
      <h2>BOOK YOUR TABLE NOW</h2>
      <div className="rt-card-container">
        <form className="rt-form" onSubmit={sendEmailHandler}>
          <div className="rt-form-row">
            <label>Select Day</label>
            <div className="datepicker">
              <DatePicker
                selected={selectedDate}
                onChange={(date) => {
                  setSelectedDate(date)
                }}
                minDate={startDay}
                maxDate={endDay}
                dateFormat="MM/dd"
                placeholderText="MM/dd"
                name="selectedDate"
              />
            </div>
          </div>

          <div className="rt-form-row">
            <label>Select Time</label>
            <select
              className="rt-select"
              required
              value={hour}
              onChange={(e) => {
                setHour(e.target.value)

                let numHour = parseInt(e.target.value, 10)
                setSelectedHour(numHour)
              }}
              name="hour"
            >
              <option value="hh">hh</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
            </select>

            <select
              className="rt-select"
              required
              value={minute}
              onChange={(e) => {
                setMinute(e.target.value)

                let numMinute = parseInt(e.target.value, 10)
                setSelectedMinute(numMinute)
              }}
              name="minute"
            >
              <option value="mm">mm</option>
              <option value="00">00</option>
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="45">45</option>
              <option value="59">59</option>
            </select>
          </div>

          <div className="rt-form-row">
            <label>Full Name</label>
            <input
              type="text"
              required
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
              name="name"
            />
          </div>

          <div className="rt-form-row">
            <label>Email</label>
            <input
              type="email"
              required
              value={replyto}
              onChange={(e) => {
                setReplyto(e.target.value)
              }}
              name="replyto"
            />
          </div>

          <div className="rt-form-row">
            <label>Phone</label>
            <input
              type="text"
              required
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value)
              }}
              name="phone"
            />
          </div>

          <div className="rt-form-row">
            <label>Number of Seats</label>
            <select
              className="rt-select"
              required
              value={seats}
              onChange={(e) => {
                setSeats(e.target.value)
              }}
              name="seats"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
          </div>

          <div className="rt-form-row">
            <label>Special Note</label>
            <textarea
              cols="30"
              rows="3"
              value={note}
              onChange={(e) => {
                setNote(e.target.value)
              }}
              name="note"
            ></textarea>
          </div>

          <div className="rt-form-row">
            <input type="submit" value="BOOK TABLE" />
          </div>
        </form>
      </div>
    </div>
  )
}

export default ReserveTable
