export const categoryData = [
    {
        titleC: '滷味',
        titleE: 'Appetizer',
        mainCat: 'Appetizer',
    },
    {
        titleC: '炸點',
        titleE: 'Fried',
        mainCat: 'Fried',
    },
    {
        titleC: '鍋品',
        titleE: 'Hotpot',
        mainCat: 'Hotpot',
    },
    {
        titleC: '菜類 飯類',
        titleE: 'Dishes & Meals',
        mainCat: 'DishesMeals',
    },
    {
        titleC: '白飯',
        titleE: 'Rice',
        mainCat: 'Rice',
    },
    {
        titleC: '麵類',
        titleE: 'Noodles',
        mainCat: 'Noodles',
    },
    {
        titleC: '湯類',
        titleE: 'Soup',
        mainCat: 'Soup',
    },
    {
        titleC: '飲品類',
        titleE: 'Drinks',
        mainCat: 'Drinks',
    },
    {
        titleC: '合菜系列',
        titleE: 'Meal in big box',
        mainCat: 'MealInBigBox',
    },
]
