import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listOrderMine } from '../actions/orderActions'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'

//socket.io stuff
import socketIOClient from 'socket.io-client'

const ENDPOINT =
  window.location.host.indexOf('localhost') >= 0
    ? 'http://127.0.0.1:5000'
    : window.location.host

//global, used in both useEffect() and event handlers
let sk

export default function OrderHistoryScreen(props) {
  const orderMineList = useSelector((state) => state.orderMineList)
  const { loading, error, orders } = orderMineList
  const dispatch = useDispatch()

  const [socket, setSocket] = useState(null)

  useEffect(() => {
    dispatch(listOrderMine())
  }, [dispatch])

  //for socket related
  useEffect(() => {
    //init socket, and REGISTER socket functions
    if (!socket) {
      //executed once
      //init socketIO instance
      sk = socketIOClient(ENDPOINT)
      //
      setSocket(sk)

      //sk will REGISTER an event listener on 'message'
      sk.on('orderCancelFailed', (orderId) => {
        alert(`Sorry, Order Cancel FAILED (3 minutes expired), with ${orderId}`)
      })
    }
  }, [socket])

  const CancelOrderHandler = (order) => {
    //have server to stamp time (for cancel order timeout)
    sk.emit('onOrderCancelTimerEnd', order._id)
  }

  return (
    <div>
      <h1>Order History</h1>
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>DATE</th>
              <th>TOTAL</th>
              <th>DETAILS</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <tr key={order._id}>
                <td>{parseInt(order._id.toString().substr(0, 8), 16)}</td>
                <td>{order.createdAt.substring(0, 10)}</td>
                <td>{order.totalPrice.toFixed(2)}</td>

                <td>
                  <button
                    type="button"
                    className="small"
                    onClick={() => {
                      props.history.push(`/ehome/order/${order._id}`)
                    }}
                  >
                    Details
                  </button>
                </td>

                <td>
                  <button
                    type="button"
                    className="small"
                    onClick={() => CancelOrderHandler(order)}
                  >
                    Cancel
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}
